// extracted by mini-css-extract-plugin
export var bannerDescription = "corporate-training-module--banner-description--J+TkO";
export var buttonWrapper = "corporate-training-module--button-wrapper--BC76y";
export var carousel = "corporate-training-module--carousel--OdMbP";
export var courses = "corporate-training-module--courses--emED3";
export var logoWrapper = "corporate-training-module--logo-wrapper--2EBWm";
export var nextSession = "corporate-training-module--next-session--7jsJP";
export var noCarousel = "corporate-training-module--no-carousel--XWr9A";
export var noSessions = "corporate-training-module--no-sessions--n3iGU";
export var partners = "corporate-training-module--partners--hc+3b";
export var product = "corporate-training-module--product--qowIg";
export var productCardsWrapper = "corporate-training-module--product-cards-wrapper--lTjLz";
export var productInfo = "corporate-training-module--product-info--Nd0aM";
export var productLink = "corporate-training-module--product-link--L2H6e";
export var skill = "corporate-training-module--skill--0J0Zg";
export var skills = "corporate-training-module--skills--IG9ij";
export var subtitle = "corporate-training-module--subtitle--f0q8n";
export var testimonials = "corporate-training-module--testimonials--+cVEo";
export var title = "corporate-training-module--title--OWcfT";
export var upcomingSession = "corporate-training-module--upcoming-session--cxVki";
export var upskill = "corporate-training-module--upskill--OAb27";
export var upskillCta = "corporate-training-module--upskillCta--gNSxL";